/* eslint-disable no-useless-escape */
<template>
    <div>
                        <a-card class="edit-card" style="background-color:transparent;padding:0;border:0; margin:0;" :loading="saving">
                        <a-tabs  tab-position="left">

                  <template v-for="(group,gid) in contenttype.groups">
                        <a-tab-pane :key="gid"  :tab="group.name" v-if="group.child.length">
                        <template>

                                <div class="pl-lg-4">
                                    <div class="row">
                        <div class="col-lg-12" :key="index" v-for="(field, index) in group.child">
                              <span class="field-collection mb-4" v-if="field.type === '17'">
                              <label class="form-control-label">{{field.title}}</label>
                              <template v-for="(fiel,indexx) in fieldData[field.slug]">

 <span :key="componentKey + '_' + indexx" >
       <div class="row" style="position:relative" v-if="field.fields">

       <field :input="fieldinput" :key="fiel[inde]" :i="indexx" v-model="fiel[inde]" :options="fieldinput.options" :cl="field.fields[0].length" v-for="(fieldinput,inde) in field.fields[0]"/>
       <a-icon class="del-but float-right" type="minus-circle-o"  @click="() => removefc(indexx,index)"/></div>
     </span>
    </template>
      <div v-if="field.multi === '1'" style="margin-left:20px;">
      <a-button type="dashed" class="mb-2 float-right" @click="addColfields(field.fid)"><a-icon type="plus" style="display:block;margin-top:2px;float:left;" />Pridať</a-button>
      </div>
    </span>
                                           <div class="form-item-holder" v-if="field.type === '1'"> <a-input alternative=""
                                                        :label="field.title"
                                                        :key="componentKey"
                                                        @change="componentKey += 1"
                                                        input-classes="form-control-alternative"
                                                        v-model="fieldData[field.slug]"
                                            />
                                            <i class="fal fa-toolbox auto-url" title="Vygeneruj automatické url z názvu" @click="setUrl"  v-if="field.slug === 'url' && fieldData['title']"></i>
                                            <i class="fal fa-globe-europe auto-url" title="Preložiť" v-if="field.translatable === '1'"></i>
                                               <div class="sub-translations" v-if="field.translatable === '1'">
<div class="translation" style="position:relative;"  :key="componentKey + translate.short" v-for="translate in languages">
                                                <div  v-if="fieldData.language !== translate.short">
                                                    <a-input alternative=""
                                                               input-classes="form-control-alternative"
                                                               v-model="fieldData[field.slug + ':' + translate.short]"/>
    <i style="position:absolute;top:10px;right:15px" title="Preložiť">{{translate.title}}</i></div>
                                               </div>
                                               </div>
                                           </div>

                                            <a-input alternative="" v-if="field.type === '6'"
                                                        :label="field.title"
                                                        input-classes="form-control-alternative"
                                                        type="number"
                                                        v-model="fieldData[field.slug]"
                                            />
                                            <a-input alternative="" v-if="field.type === '5'"
                                                        :label="field.title"
                                                        input-classes="form-control-alternative"
                                                        type="number"
                                                        v-model="fieldData[field.slug]"
                                            />

                                            <div class="mb-4" v-if="field.type === '10'">
                                                <label class="form-control-label">{{field.title}}</label>
                                                <builder :disabled="contenttype.aw" :data="field.slug" :key="refreshAll" :url="fieldData.url" :values="fieldData[field.slug]" @changed="(ee) => onChildChangeBuilder(ee,field.slug)"></builder>
                                            </div>
                                            <div class="form-item-holder mb-4" v-if="field.type === '4'" >
                                                <label class="form-control-label">{{field.title}}</label>
                                                <ckeditor :editor="editor" v-model="fieldData[field.slug]"  :config="editorConfig"></ckeditor>
                                                <i class="fal fa-globe-europe auto-url" title="Preložiť" v-if="field.translatable === '1'"></i>
                                                <div class="sub-translations" style="margin-top:10px;" v-if="field.translatable === '1'">
                                                    <div class="translation" style="position:relative;"  :key="componentKey + translate.short" v-for="translate in languages">
                                                        <div  v-if="fieldData.language !== translate.short">
                                                            <ckeditor :editor="editor" v-model="fieldData[field.slug + ':' + translate.short]"  :config="editorConfig"></ckeditor>
                                                            <i style="position:absolute;top:10px;right:15px">{{translate.title}}</i></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class=" mb-4" v-if="field.type === '2' && field.slug !== 'bigeronhotel'">
                                                <label class="form-control-label">{{field.title}}</label>
                                                <a-select style="width: 100%"   :filter-option="(e,f) => compareData(e,f,field.id)" v-if="field.multi === '0'" defaultValue="1"  v-model="fieldData[field.slug]" :options="options[field.id]"/>
                                                <a-select v-else  mode="multiple" defaultValue="1" v-model="fieldData[field.slug]"   :filter-option="(e,f) => compareData(e,f,field.id)" :options="options[field.id]"/>
                                            </div>

                                            <div class=" mb-4" v-if="field.type === '2' && field.slug === 'bigeronhotel'">
                                                <label class="form-control-label">{{field.title}}</label>
                                                <a-select mode="multiple" defaultValue="1" v-model="fieldData[field.slug]" :options="hotels"/>
                                            </div>

                                            <a-checkbox class="mb-3" v-model="fieldData[field.slug]"  v-if="field.type === '7'">
                                                {{field.title}}
                                            </a-checkbox>


                                            <div :key="'mapid-' + mapID" class="mb-4" v-if="field.type === '21'">
                                                <label class="form-control-label">{{field.title}}:
                                                    <span>
                                                    <a-input-group size="large">
                                                    <a-row :gutter="9" class="top-bar"><a-col :span="6">
                                                    <a-input label="latitude" type="text" style="display:inline" v-model="lat" />
                                                    </a-col>
                                                    <a-col :span="6">
                                                    <a-input label="longitude" type="text" style="display:inline" v-model="lng" />
                                                    </a-col>
                                                   <a-col :span="12">
                                                     <a-upload name="upload"  class="action-button-gpx" :multiple="false"  :action="url.API_URL + '/upload/file'" :headers="head" @change="addedGPX">
                                                     <a-button size="large"  type="default" class="action-button-gpx">Nahrať GPX</a-button></a-upload>
                                                     <a-button size="large" v-if="mid" target="_blank" :href="'https://apiv2.bigeron.com/api/web/downloadpdf/' + mid" type="default" class="action-button-gpx">Zobraziť PDF</a-button>
                                                    </a-col>
                                                    </a-row>
                                                 </a-input-group>
                                                      </span></label>
                                                <a-row>
                                                <a-col :span="24" >
                                                    <MapLeaflet  :gpxContent="mapID" :value="resolveAddress(field.slug)" :label="field.title" :dat="field.slug"  @changed="(e) => onMapChange(e,field.slug)"></MapLeaflet>
                                                    </a-col>
                                                </a-row></div>

                                            <div class="mb-4" v-if="field.type === '3'">
                                                <label class="form-control-label">{{field.title}}</label>
                                                <image-upload :data="fieldData[field.slug]" :label="field.title" :dat="field.slug"  @changed="(e) => onChildChange(e,field.slug)"></image-upload>

                                            </div>
                                            <div class="mb-4" v-if="field.type === '19'">
                                                <label class="form-control-label">{{field.title}}</label>
                                                 <upload :data="fieldData[field.slug]" :label="field.title" :dat="field.slug"  @changed="(e) => onChildChange(e,field.slug)"></upload>

                                            </div>
                                            <div class="mb-4" v-if="field.type === '11'">


                                                <label class="form-control-label">{{field.title}}</label>

                                                <a-upload-dragger
                                                        name="upload"
                                                        :multiple="true"
                                                        :customRequest="(e) => testo(e,field.slug)"
                                                        :headers="head"
                                                        :showUploadList="false"
                                                        style="margin-bottom:20px;display:inline-block;width:100%;"
                                                >
                                                    <p class="ant-upload-drag-icon" style="padding-top:20px">
                                                        <a-icon type="file-image" />
                                                    </p>
                                                    <p class="ant-upload-text">
                                                     Klikni, alebo premiestni súbory do tohoto poľa
                                                    </p>

                                                </a-upload-dragger>

<div class="setup-panel" v-if="fieldData[field.slug] && JSON.parse(fieldData[field.slug])[Object.keys(JSON.parse(fieldData[field.slug]))[0]]">

                                                <div class="mb-4 text-right" >
                                                    <label class="form-control-label show-example text-right w-50">globálny štýl galérie
                                                        <a-icon type="picture" :style="{ fontSize: '25px', marginLeft: '15px', color: '#08c' }" ></a-icon>
                                                        <div class="demo-image" v-if="JSON.parse(fieldData[field.slug])[Object.keys(JSON.parse(fieldData[field.slug]))[0]].image.relative">
                                                            <label class="form-control-label"></label>
                                                            <img :src="IMG_URL + (typeof JSON.parse(fieldData[field.slug])[Object.keys(JSON.parse(fieldData[field.slug]))[0]].image.style != 'undefined' ? JSON.parse(fieldData[field.slug])[Object.keys(JSON.parse(fieldData[field.slug]))[0]].image.style : 'contentGrid') + JSON.parse(fieldData[field.slug])[Object.keys(JSON.parse(fieldData[field.slug]))[0]].image.relative" class="small-image">
                                                        </div>
                                                    </label>
                                                    <div class="w-25"  v-if="fieldData[field.slug]">

                                                </div>
                                                    <a-select class="w-50 float-right"  placeholder="globálny štýl galérie" @change="(e) => handleGlobalEdit(e,field.slug)" :options="styles"/>
                                                   </div>

<div class="gallery-images" :key="fieldData[field.slug]" v-if="fieldData[field.slug]">
    <draggable tag="div" @change="(e) => log(e,field.slug)" :list="JSON.parse(fieldData[field.slug])" v-bind="dragOptions" @start="drag=true" @end="drag=false"  class="list-group" handle=".handle">
    <div v-for="(imageItem,index) in JSON.parse(fieldData[field.slug])" class="gallery-image-holder" :key="'img'+field.slug+'_'+index">
        <span class="handle"><div class="title" v-if="imageItem.image.title">{{imageItem.image.title}}</div>
<div class="alttag" v-if="imageItem.image.alt">{{imageItem.image.alt}}</div>
<img class="big" :src="IMG_URL + 'contentGrid' + imageItem.image.relative">
<div class="image-setup"><a-icon type="edit" @click="setupImage(imageItem.image,index,field.slug)"/><a-icon type="delete" @click="deleteThis(index,field.slug)"/></div></span>
    </div></draggable>
</div></div>
                                            </div>
                                            <div class="mb-4" v-if="(field.type === '13' && field.multi === '1')">
                                                <label class="form-control-label">{{field.title}}</label>
                                                <multi v-if="fieldData[field.slug]" :data="{'item.multiplier' :JSON.parse(fieldData[field.slug]) }"  multiplier="true" :options="options[field.id]" :items="['item.multiplier']" :editid="index" @changed="(e) => onChildChangeMulti(e,field.slug)"/>
                                                <multi v-else  :items="['item.multiplier']" :options="options[field.id]" :editid="index"  multiplier="false" @changed="(e) => onChildChangeMulti(e,field.slug)"/>
                                            </div>
                                            <div class="mb-4" v-if="field.type === '16'">
                                                <label class="form-control-label" style="width:100%">{{field.title}}</label>
                                                <div v-if="fieldData[field.slug]">
                                                    <div v-for="(fd, index) in fieldData[field.slug]" :key="field.id + index" class="date-row">
                                                        <div v-if="fd" style="margin-left:20px;">
                                                            {{formatDateP(fd[0])}} - {{formatDateP(fd[1])}}
                                                        </div>
                                                        <a-icon class="dynamic-delete-button date-delete" type="minus-circle-o" @click="() => remove(index,field.slug)"/>
                                                    </div>
                                                </div>
                                                <div  class="ant-row-flex"><a-range-picker class="mb-10" :locale="locale" v-model="temp[field.slug]" />

                                                    <a-button type="dashed" class="mb-4" v-if="temp[field.slug]" @click="onChange(temp[field.slug],field.slug)">
                                                        <a-icon type="plus" /> Pridať
                                                    </a-button>
                                                </div>
                                            </div>
                                            <div class="mb-4" v-if="field.type === '14'">
                                                <label class="form-control-label">{{field.title}}</label>
                                                <a-date-picker v-model="fieldData[field.slug]" v-if="fieldData[field.slug]"  style="width: 100%" :defaultValue="formatDate(fieldData[field.slug])" :locale="locale" />
                                                <a-date-picker v-model="fieldData[field.slug]"  v-else style="width: 100%" :locale="locale" />
                                            </div>
                                            <div class="mb-4" v-if="field.type === '15'">
                                                <label class="form-control-label">{{field.title}}</label>
                                                <multi v-if="fieldData[field.slug]" :data="JSON.parse(fieldData[field.slug])" :slug="field.slug" multiplier="false" :items="['title','text']" :editid="index" @changed="(e) => onChildChangeMultiP(e,field.slug)"/>
                                                <multi v-else  :items="['title','text']"  :editid="index" :slug="field.slug"  multiplier="false" @changed="(e) => onChildChangeMultiP(e[field.slug],field.slug)"/>
                                            </div>
                                            <div class="mb-4" v-if="( field.type === '12' && field.multi === '0')">
                                                <!---     <autoComplete slug="taxonomysearch" :data="options[field.id]" :contractor="contexts[field.id]"  :title="field.title" class="autoitem" @changed="(e) => onChildTaxChange(e,field.slug)"></autoComplete> -->
                                                <label class="form-control-label">{{field.title}}</label>
                                                <a-select defaultValue="1"  style="width: 100%"  v-model="fieldData[field.slug]">
                                                    <a-select-option :value="0">Bez priradenia</a-select-option>
                                                    <a-select-option v-for="op in options[field.id]" :key="op.value">{{op.label}}</a-select-option>
                                                </a-select>


                                            </div>
                                            <div class="mb-4" v-if="field.type === '8' && field.multi !== '1'">
                                                <label class="form-control-label">{{field.title}}</label>
                                                <a-select
                                                        style="width: 100%"
                                                        @change="(e) => onChildChange(e,field.slug)"
                                                        :defaultValue = "fieldData[field.slug]"
                                                        placeholder="Vyberte si zo zoznamu"
                                                >
                                                    <a-select-option v-for="op in options[field.id]" :key="op.value">{{op.label}}</a-select-option
                                                    >
                                                </a-select> </div>
                                            <div class="mb-4" v-if="field.type === '9'">
                                                <div v-if="field.slug === 'city'">
                                                    <label class="form-control-label">{{field.title}}</label>
                                                    <div v-if="Array.isArray(fieldData[field.slug])">{{fieldData[field.slug][1]}}</div>

                                                    <a-auto-complete
                                                            v-model="fieldData[field.slug]" :dataSource="options[field.id]" @select="(e) => onSelect(e,field.slug)" @search="(e) => onSearch(e,field.id)">   <template slot="dataSource">
                                                        <a-select-option v-for="item in dataSource" :key="item.value" :title="item.text"></a-select-option>
                                                    </template>
                                                    </a-auto-complete>
                                                </div>
                                            </div>
                                            <div class="mb-4" v-if="field.type === '8' && field.multi === '1'">
                                                <label class="form-control-label">{{field.title}}</label> <a-button style="position: absolute; right: 17px; top: 0;" size="small" @click="addContent(field.id)">+ pridať</a-button>
                                                <a-select v-if="fieldData[field.slug]"
                                                   :filter-option="(e,f) => compareData(e,f,field.id)"
                                                   mode="multiple" style="width: 100%"  v-model="fieldData[field.slug]" placeholder="Please select">
                                                    <a-select-option v-for="op in options[field.id]" :key="op.value">{{op.label}}</a-select-option>
                                                </a-select>

                                               <a-select v-else
                                                          mode="multiple"
                                                          :filter-option="(e,f) => compareData(e,f,field.id)"
                                                          style="width: 100%"
                                                          @change="(e) => onChildChange(e,field.slug)"
                                                          placeholder="Please select"
                                                >
                                                    <a-select-option v-for="op in options[field.id]" :key="op.value">{{op.label}}</a-select-option
                                                    >
                                                </a-select>


                                            </div>

                                            <div class="mb-4" v-if=" field.type === '12' && field.multi === '1'">
                                                <label class="form-control-label">{{field.title}}</label>
                                                <a-select v-if="fieldData[field.slug]"
                                                          mode="multiple" style="width: 100%" :defaultValue = "JSON.parse(fieldData[field.slug])"
                                                          @change="(e) => onChildChange(e,field.slug)" placeholder="Please select">
                                                    <a-select-option v-for="op in options[field.id]" :key="op.value">{{op.label}}</a-select-option>
                                                </a-select>

                                                <a-select v-else
                                                          mode="multiple"
                                                          style="width: 100%"
                                                          @change="(e) => onChildChange(e,field.slug)"
                                                          placeholder="Please select"
                                                >
                                                    <a-select-option v-for="op in options[field.id]" :key="op.value">{{op.label}}</a-select-option
                                                    >
                                                </a-select>

                                            </div></div>

                                    </div>
                                </div>
                        </template>


                            </a-tab-pane>
                            </template>

                        </a-tabs>
                        <div class="col-lg-12">
                            <hr>
                            <div class="mb-4">

                                <div class="text-left position-absolute" v-if="revisions">
                                    <a-button style="float:left; margin-top:-2px;"  class="btn btn-primary" @click="revisionsShow = true"><i class="fal fa-history" style="position:relative;top:-6px;"></i></a-button>
                                    <span class="revision-date" style="display:block;float:left;font-size:12px;line-height:14px">Posledná revízia:<br>{{formatDateP(revisions[0].created)}}</span>
                                </div>
                                <div class="text-right">
                                     <a-popconfirm placement="topRight"
                                                  @confirm="confirm(id)"
                                                  @cancel="cancel" v-if="mid"
                                                  okText="Áno"
                                                  cancelText="Nie">
                                        <template slot="title">
                                            <h5 color="danger" >Prajete si tento obsah vymazať?</h5>
                                            Položka bude trvalo odstránená z vašej stránky.
                                        </template>
                                        <a class="btn  text-default  btn-icon btn-neutral"><span class="btn-inner--icon" style="margin-right:5px"><i class="fas fa-trash"></i></span>Zmazať</a>
                                    </a-popconfirm>
                                    <button class="btn btn-primary" @click="handleSubmit"><span class="btn-inner--icon" style="margin-right:5px"><i class="fas fa-save pr-1"></i></span>Uložiť</button>
                                </div>
                            </div>
                        </div>
                        </a-card>

        <a-modal title="Pridajte obsah" centered  v-model="addContentShow" :width="900" :footer="null" v-if="addContentShow" cancelText="Zrušiť" @cancel="addContentShow = false" okText="Vytvoriť" @ok="addSelect">
        <fields :id="fieldtoselect" @change="settheavalue"/>
        </a-modal>
        <a-modal title="Revízie" centered  v-model="revisionsShow" v-if="revisionsShow" cancelText="Zrušiť" @cancel="revisionsShow = false" okText="Vrátiť" @ok="revert">
            <a-alert class="mb-2" message="Vráti stránku do stavu z nasledujúceho času. Ak chcete zmeny uložiť, je potrebné stlačiť Uložiť (alebo použiť CTRL + S)." type="info" />
            <a-select v-model="revision" class="w-100"  placeholder="Vyberte si z predchádzajúcich verzií">
                <a-select-option v-for="op in revisions" :key="op.id">{{op.created}}</a-select-option>
            </a-select>
        </a-modal>

        <a-modal title="Nastavenia obrázka v galérii" centered  v-model="ImagesetupVisible" v-if="ImagesetupVisible" @ok="setupImageOK">
        <a-form-item  label="Štýl obrázka" class="image-property">
            <a-select placeholder="štýl obrázka" class="w-100" v-model="imageToSet.style" :options="styles"/>
        </a-form-item>
            <a-form-item label="Názov obrázka" class="image-property">
                <a-input placeholder="Názov"  class=" "  v-model="imageToSet.title" />
            </a-form-item>
            <a-form-item   label="Alt tag obrázka" class="image-property">
                <a-input placeholder="Alt"  class=" "  v-model="imageToSet.alt" />
            </a-form-item>
        </a-modal>
    </div>
</template>
<script>
    import {getURL} from "../api";
    import {authHeader} from "../_helpers";
    import {dataService} from "../_services";
    import Vue from "vue"
    import FormItem from 'ant-design-vue'
    import ImageQ from "./content/Image";
    import Upload from "./content/Upload";

    import {IMG_URL} from "../api";

    import AUpload from "ant-design-vue/es/upload";
    import AFormItem from "ant-design-vue/es/form/FormItem";
    import AInput from "ant-design-vue/es/input";
    import ADatePicker from "ant-design-vue/es/date-picker";
    import {Cropper} from 'vue-advanced-cropper'
    import Builder from "./content/Builder";
    import MapLeaflet from "./content/Map"
    import Multi from "./content/multi";
    import moment from 'moment'
    import 'moment/locale/sk';
    import Field from './Field';
    import Fields from './mapFields';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    // Import translations for the German language.
    import '@ckeditor/ckeditor5-build-classic/build/translations/sk';

    import draggable from "vuedraggable";
    // eslint-disable-next-line no-unused-vars
    import UploadAdapter from './UploadAdapter';

    var locale = {
        "lang": {
            "placeholder": "Vybrať dátum",
            "rangePlaceholder": ["Začiatok", "Koniec"],
            "today": "Dnes",
            "now": "Teraz",
            "backToToday": "Späť na dnes",
            "ok": "Ok",
            "clear": "Vymazať",
            "month": "Mesiac",
            "year": "Rok",
            "timeSelect": "Vybrať čas",
            "dateSelect": "Vybrať dátum",
            "monthSelect": "Vybrať mesiac",
            "yearSelect": "Vybrať rok",
            "decadeSelect": "Vybrať dekádu",
            "yearFormat": "YYYY",
            "dateFormat": "M/D/YYYY",
            "dayFormat": "D",
            "dateTimeFormat": "M/D/YYYY HH:mm:ss",
            "monthFormat": "MMMM",
            "monthBeforeYear": true,
            "previousMonth": "Predchádzajúci mesiac (PageUp)",
            "nextMonth": "Nasledujúci mesiac (PageDown)",
            "previousYear": "Minulý rok (Control + left)",
            "nextYear": "Nasledujúci rok (Control + right)",
            "previousDecade": "Minulá dekáda",
            "nextDecade": "Nasledujúca dekáda",
            "previousCentury": "Minulé storočie",
            "nextCentury": "Nasledujúce storočie"
        },
        "timePickerLocale": {
            "placeholder": "Vybrať čas"
        },
        "dateFormat": "DD.MM.YYYY",
        "dateTimeFormat": "DD.MM.YYYY HH:mm:ss",
        "weekFormat": "YYYY-wo",
        "monthFormat": "YYYY-MM"
    }

    Vue.use(FormItem);
    Vue.use(Cropper);


    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type: mime});
    }

    export default {
        name: 'editcontent',
        components: {Multi,
            Builder,
            draggable,
            AUpload,
            AInput,
            ADatePicker,
            MapLeaflet,
            "field":Field,
            "fields":Fields,  AFormItem,'image-upload' : ImageQ,
            Upload},
        props: ['id','mid'],
        data() {
            return {
                url: getURL(),
                fieldData: {},
                colorsto: JSON.parse(localStorage.colors),
                formData: null,
                fileList: [],
                locale,
                fieldtoselect:false,
                IMG_URL,
                gpxLoader:false,
                mapID: 0,
                hotels:[],
                dataSource:{},
                revision:false,
                languages:[],
                translations:[],
                revisions:false,
                 fetching: false,
                hasOwntitle:false,
                autourl:true,
                addContentShow: false,
                generating: false,
                refreshAll:0,
                lat:0,
                lng:0,
                revisionsShow:false,
                imageToSet:{},
                unitID:JSON.parse(localStorage.user).data.unitID,
                saving:false,
                showoptions:[],
                contexts: {},
                ImagesetupVisible:false,
                styles:[],
                temp:{},
                componentKey:0,
                reloadimages:{},
                editor: ClassicEditor,
                editorConfig: {
                    heading: {
                        options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                            { model: 'heading3', view: 'h3', title: 'Písané', class: 'ck-heading_heading3' },
                        ]
                    },
                    toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'insertTable', '|', 'imageUpload', 'mediaEmbed', '|', 'undo', 'redo', '|', 'HtmlEmbed', ],
                    table: {
                        toolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
                    },
                    extraPlugins: [this.uploader],
                    language: 'sk',
                },
                vars:  {},
                head: authHeader(),
                types: [
                    {"value": '1', "label": 'Text'},
                    {"value": '2', "label": 'Výberovník'},
                    {"value": '3', "label": 'Obrázok'},
                    {"value": '4', "label": 'Dlhý text'},
                    {"value": '5', "label": 'Celé číslo'},
                    {"value": '6', "label": 'Desatiné číslo'},
                    {"value": '7', "label": 'Logická hodnota Áno/Nie'},
                ],
                ct: null,
                cttt: null,
                formItemLayout: {
                    labelCol: {
                        xs: {span: 24},
                        sm: {span: 4},
                    },
                    wrapperCol: {
                        xs: {span: 24},
                        sm: {span: 20},
                    },
                },
                formItemLayoutWithOutLabel: {
                    wrapperCol: {
                        xs: {span: 24, offset: 0},
                        sm: {span: 20, offset: 4},
                    },
                },
                contenttypeId: {
                    uid: this.id
                },
                options: [],
                contenttype: {
                    title: '',
                    type: '',
                },
            }
        },
        methods: {
            IsJsonString(str) {
                try {
                    JSON.parse(str);
                } catch (e) {
                    return false;
                }
                return true;
            },
            addSelect() {

            },
            confirm(e) {
                 dataService.axiosDelete('content', e).then(results => {
                    if (results) {
                         this.$router.push('/content/' + this.cttt)
                  }
                });
            },
            settheavalue(e)
            {
  this.options[this.addContentShow].push(e);

let self = this;

Object.keys(this.contenttype.fields).forEach(function (key){
var obj = self.contenttype.fields[key];
if(obj.id === self.addContentShow)
                        {
                         if(!self.fieldData.hasOwnProperty(obj.slug))
                         {
                          self.fieldData[obj.slug] = [];
                         }
                          self.fieldData[obj.slug].push(e.value)
                        }

});

                this.addContentShow = false;
            },
            addContent(fieldID)
            {
            this.fieldtoselect = this.contexts[fieldID];
this.addContentShow = fieldID;
            },
            cancel() {
            },
            forceRerender() {
                this.componentKey += 1;
            },
            slugify(string) {
                const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
                const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
                const p = new RegExp(a.split('').join('|'), 'g')

                return string.toString().toLowerCase()
                    .replace(/\s+/g, '-') // Replace spaces with -
                    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
                    .replace(/&/g, '-and-') // Replace & with 'and'
                    // eslint-disable-next-line no-useless-escape
                    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
                    // eslint-disable-next-line no-useless-escape
                    .replace(/\-\-+/g, '-') // Replace multiple - with single -
                    .replace(/^-+/, '') // Trim - from start of text
                    .replace(/-+$/, '') // Trim - from end of text
            },
            setUrl()
            {
                this.fieldData['url'] = this.slugify(this.fieldData['title']);
                this.forceRerender();
            },
            uploader(editor)
            {
                editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
                    return new UploadAdapter( loader );
                };
            },
            sendImage(formData,slug)
            {

                const pa = this;
                this.axios.post(this.url.API_URL + '/upload/content',
                    formData,
                    {
                        headers: authHeader(true)
                    }
                ).then(function (data) {
                    pa.datatosave = data.data[0];

                    let items = [];
// eslint-disable-next-line no-unused-vars
let maxvalue  = 0;

                    var u = {}; u.image = data.data[0];

                    if(pa.IsJsonString(pa.fieldData[slug]) && pa.fieldData[slug] !== null)
                    {
                        items = JSON.parse(pa.fieldData[slug]);
                         items.push(u);

                    }
                    else
                    {
                        items.push(u);
                    }

                    pa.fieldData[slug] = JSON.stringify(items);
//pa.reloadimages[slug] = countitem + 1;

                 //   pa.$emit('changed',  pa.datatosave);
                });

            },
            cloneNode()
            {
                dataService.axiosPost('translate/' + this.id, { 'lang' : this.fieldData.language}).then(results => {
                    if(results.data)
                    {this.$router.push('/edit-content/' + results.data[0])}
                });
            },
            hasAlpha (context, canvas) {
                var data = context.getImageData(0, 0, canvas.width, canvas.height).data,
                    hasAlphaPixels = false;
                for (var i = 3, n = data.length; i < n; i+=4) {
                    if (data[i] < 255) {
                        hasAlphaPixels = true;
                        break;
                    }
                }
                return hasAlphaPixels;
            },
            testo(e,slug)
            {
                let maxW = 1920;
                let maxH = 500;

                var img = new Image;
                let formData = new FormData();
                const pa = this;
                img.onload = function() {
                    var canvas = document.createElement('canvas');
                    var ctx=canvas.getContext("2d");
                    var iw=img.width;
                    var ih=img.height;
                    if(ih > maxH || iw > maxW)
                    {

                        //  var scale=Math.min((maxW/iw),(maxH/ih));
                        var scale = maxW/iw;
                        var iwScaled=iw*scale;
                        var ihScaled=ih*scale;
                        canvas.width=iwScaled;
                        canvas.height=ihScaled;
                        ctx.drawImage(img,0,0,iwScaled,ihScaled);
                        if(pa.hasAlpha(ctx,canvas))
                            this.imgCroppFile = canvas.toDataURL("image/png",1);
                        else
                            this.imgCroppFile = canvas.toDataURL("image/jpeg",1);
                        formData.append('upload', dataURLtoFile(this.imgCroppFile, e.file.name));
                    }
                    else
                    {
                        formData.append('upload', e.file);
                    }
                    pa.sendImage(formData,slug);

                }

                img.src = URL.createObjectURL(e.file);

                return false
            },
            normFile(e) {

                if (Array.isArray(e)) {
                    return e;
                }
                return e && e.fileList;


            },
            handleEdit() {
                this.$emit('changed', this.datatosave);
            },
            array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    },
    addColfields(fieldindex)
            {
          var fields =  this.contenttype.fields[fieldindex].fields[0];
          this.contenttype.fields[fieldindex].fields.push(fields);
          this.fieldData[this.contenttype.fields[fieldindex].slug][parseInt(this.fieldData[this.contenttype.fields[fieldindex].slug].length)] = new Array(fields.length).fill("");
          this.componentKey = this.componentKey + 1;
            },
            log(e,slug)
            {
                var arr = JSON.parse(this.fieldData[slug]);


                arr = this.array_move(arr, e.moved.oldIndex, e.moved.newIndex);

                this.fieldData[slug] = JSON.stringify(arr);
            },
            removefc(ind,fieldindex)
            {
                this.contenttype.fields[fieldindex].fields.splice(ind, 1);
                this.fieldData[this.contenttype.fields[fieldindex].slug].splice(ind, 1).filter(val => val);
            },
            onChildChangeBuilder(value,slug) {
                this.fieldData[slug] = JSON.stringify(value); // someValue
            },
            formatDate(dt) {

                if(moment(dt, moment.ISO_8601, true).isValid())
                    return  moment(moment(dt).format('YYYY/MM/DD'));
                else
                    return moment(moment.unix(dt),'YYYY/MM/DD')
            },
            formatDateP(dt) {

                if(moment(dt, moment.ISO_8601, true).isValid())
                    return  moment(dt._d).format('lll');
                else
                    return moment.unix(dt).format('lll');


            },
            onChildChangeFc(input,od,ind)
            {
                this.fieldData[this.contenttype.fields[od].slug][od][ind] = input;

            },
            bool(v) {
                return v === "true" || v === "1";
            },
            deleteThis(index,slug)
            {
                let parsed = JSON.parse(this.fieldData[slug]);
                parsed.splice(index, 1);
                this.fieldData[slug] = JSON.stringify(parsed);


            },
            onChange(e,slug)
            {

                if(!this.fieldData[slug])
                    this.fieldData[slug] = [];
                e[0] = e[0].unix();
                e[1] = e[1].unix();
                this.fieldData[slug].push(e);
                this.temp = {};
            },
            remove(index,slug)
            {
                this.fieldData[slug].splice(index, 1);
            },
            onChildChangeMulti(value,slug)
            {
                for(var i in value){
                    this.fieldData[slug] = JSON.stringify(Object.values(value[i]));
                }

            },
            setupImage(image,index,slug)
            {

                this.imageToSet = image;
                this.imageToSet.index = index;
                this.imageToSet.slug = slug;
                this.ImagesetupVisible = true;
            },
            handleGlobalEdit(e,slug)
            {
                 let parsed = JSON.parse(this.fieldData[slug]);
                 parsed.forEach(obj => {
                          obj.image.style = e;
                });

                this.fieldData[slug] = JSON.stringify(parsed);
            },
            setupImageOK()
            {
                let parsed = JSON.parse(this.fieldData[this.imageToSet.slug]);
              parsed[this.imageToSet.index].image.title = this.imageToSet.title;
              parsed[this.imageToSet.index].image.style = this.imageToSet.style;
              parsed[this.imageToSet.index].image.alt = this.imageToSet.alt;
              this.fieldData[this.imageToSet.slug] = JSON.stringify(parsed);
                this.ImagesetupVisible = false;
            },
            onSelect(value,slug) {
                this.fieldData[slug] = value;
            },
            onSearch(e,f)
            {
                dataService.axiosFetch("dynamic/"+f, e).then(results => {
                    this.options[f] = results;
                });


            },
            jsonU(a)
            {
                var b = JSON.parse(a).map(x=>`"${x}"`).join(",")
                return JSON.parse("[ "+ b + "]");
            },
            onChildChangeMultiP(value,slug)
            {

                this.fieldData[slug] = JSON.stringify(value);
            },
            onchildMultiChange(value,slug)
            {
this.fieldData[slug] = value;
this.fetching = false;
            },
            onChildChange(value,slug) {
                this.fieldData[slug] = JSON.stringify(value);
                this.showoptions = [];
this.fetching = false;
            },
            onMapChange(value,slug) {
                this.fieldData[slug] = value.position;

                this.lat = value.position.lat;
                this.lng = value.position.lng;

                this.fieldData["coords"][0][0] = this.lat + ", " + this.lng;
                this.fieldData["gpspoint"] = {"lat": this.lat,"lng": this.lng};


                if(!this.hasOwntitle || !this.fieldData["title"])
               {
                    this.fieldData["title"] = JSON.parse(this.fieldData["json"]).tracks[0].name;
                    this.setUrl();
                    this.fieldData["language"] = "sk";
                }
                this.componentKey += 1;
            },
            resolveAddress(slug)
            {
            if(this.fieldData[slug])
            return this.fieldData[slug];
            else
            {
               if(this.fieldData["coords"])
               { if(this.fieldData["coords"][0][0])
            {
                let ll = this.fieldData["coords"][0][0].split(", ");
                return {"lat": parseFloat(ll[0]), "lng": parseFloat(ll[1])};
            }
            }}
            },
            onChildTaxChange (value,slug)
            {   this.fieldData[slug] = value;

            },
            handleSubmit() {
               this.saving = true;
                this.fieldData.unitID = this.unitID;
                    dataService.axiosPost("savetrack/" + this.contenttype.slug, this.fieldData).then(results => {
                        if(results.data)
                        {
                          this.$emit('change', {value: results.data[0], label: this.fieldData.title});
                        }
                    })
                },
savePressed(e)
{
        if ((window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)  && e.keyCode === 83) {
            if (e.repeat) { return }
            e.preventDefault();
            this.handleSubmit();
        }

},
generate()
{
    this.generating = true;
    dataService.axiosFetch("refractor", this.id).then(results => {
this.generating = false;
this.fieldData["elevation"] = results.elevation;
this.fieldData["mainview"] = results.mainview;
this.fieldData["coords"] = results.coords;

        });
},
generateGPX()
{


    this.gpxLoader = true;

               this.fieldData["mainview"] = "";
        dataService.axiosPost("generateGPX", { gps: this.fieldData["gpspoint"], title:  this.fieldData["title"] }).then(results => {
this.fieldData["file"] = results.data.data.file;
this.fieldData["json"] = results.data.data.json;
this.fieldData["centerpoint"] = results.data.data.centerpoint;
//this.fieldData["coords"][0][0] = JSON.parse(results.data.data.centerpoint)[1] + ", " + JSON.parse(results.data.data.centerpoint)[0];
this.fieldData["coords"][0][0] = results.data.data.pointLL;
this.fieldData["coords"][0][1] = results.data.data.pointDMS;

this.fieldData["length"] = results.data.data.length;
this.gpxLoader = false;
        });
},
 addedGPX(e)
            {

             if (e.fileList !== undefined)
               {
               let filedatas = e.fileList[0].response[0];
               if(!e.event)
               {
                filedatas["original"] = filedatas["uri"];
               this.fieldData["file"] = filedatas;
               this.fieldData["mainview"] = "";

 dataService.axiosPost("generateGPX", { fid: filedatas["original"]}).then(results => {
this.fieldData["json"] = results.data.data.json;
this.fieldData["centerpoint"] = results.data.data.centerpoint;
//this.fieldData["coords"][0][0] = JSON.parse(results.data.data.centerpoint)[1] + ", " + JSON.parse(results.data.data.centerpoint)[0];

this.fieldData["coords"][0][0] = results.data.data.pointLL;
this.fieldData["coords"][0][1] = results.data.data.pointDMS;

this.fieldData["length"] = results.data.data.length;
this.gpxLoader = false;
this.mapID = filedatas["original"];
});

               }}
            },
            fetch(){
let wtgId = this.mid;

                dataService.axiosFetch('track', wtgId + "/true").then(results => {
                    this.fieldData = results.data

                    this.vars = results.vars
                    this.contenttype = results.contenttypes
this.hasOwntitle = this.fieldData.title;


if(this.fieldData.file){
    this.mapID = this.fieldData.file.original
}
    if(this.fieldData["coords"])
               {   if(this.fieldData["coords"][0][0])
            {
                let ll = this.fieldData["coords"][0][0].split(", ");
                this.lat = parseFloat(ll[0]);
                this.lng = parseFloat(ll[1]);
            } }

                    let roleops = { "name": "Všeobecné","child": []};
                    this.contenttype.groups.forEach(obj => {
                  if(obj.pid === "0" && !obj.child.length)
                        {
                            roleops["child"].push(obj)
                        }

                    });
                    if(roleops)
                    {
                      this.contenttype.groups = this.contenttype.groups.slice();
                      this.contenttype.groups.unshift(roleops);
                    }
                    this.contexts = results.context
                    this.options = results.options
                    this.ct = results.ctt
                     this.revisions = results.revisions;
                    if(this.revisions)
                    {
                        this.revision = this.revisions[0].id;
                    }
                    this.cttt = results.cttt
this.saving = false;

                });


            },
            getAllOptions(inslug)
            {
                this.showoptions = this.options[inslug];
            },
            compareData(search,inslug)
            {
              let searchTerm =search.toLowerCase();
if(inslug.componentOptions.children[0].text.toLowerCase().includes(searchTerm))
return true;
            },
            updateGPS(){

            this.fieldData["coords"][0][0] = this.lat + ", " + this.lng;
            this.fieldData["gpspoint"] = {"lat": this.lat,"lng": this.lng};
              dataService.axiosPost("generateGPX", { gps: this.fieldData["gpspoint"], title:  this.fieldData["title"] }).then(results => {
this.fieldData["file"] = results.data.data.file;
this.fieldData["json"] = results.data.data.json;
this.fieldData["centerpoint"] = results.data.data.centerpoint;
this.fieldData["coords"][0][0] = JSON.parse(results.data.data.centerpoint)[1] + ", " + JSON.parse(results.data.data.centerpoint)[0];
this.gpxLoader = false;
this.mapID = this.fieldData["file"].original;
        });
            },
            revert()
            {
              if(this.revision)
              {
                  this.fieldData = {};

                  dataService.axiosFetch("revision", this.revision).then(results => {
                      this.fieldData = results.data
                      this.vars = results.vars
                      this.contexts = results.context
                      this.options = results.options
                      this.refreshAll = this.refreshAll + 1;
                  });
                  this.revisionsShow = false;
              }
            },
            handleChange(info,slug) {

                this.fieldData[slug] = null;
                if (info.file.status === 'done') {
                    this.fieldData[slug] = info.file.response[0]
                } else if (info.file.status === 'error') {
                    this.fieldData[slug] = ""
                }
            },
        },
        computed: {
            dragOptions() {
                return {
                    animation: 0,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                };
            }
        },
        created() {
            document.addEventListener("keydown", this.savePressed);
        },
        beforeDestroy() {
            document.removeEventListener('keydown', this.savePressed);
        },
        mounted() {
            if (isNaN(this.id) || isNaN(this.mid) ) {
                dataService.axiosPost('tracks',{'new': true, 'ct': this.id ,'unitid' : this.unitID }).then(results => {

                this.contenttype = results.data.data

             let roleops = { "name": "Všeobecné","child": []};
                    this.contenttype.groups.forEach(obj => {


                        if(obj.pid === "0" && !obj.child.length)
                        {
                            roleops["child"].push(obj)
                        }
                    });
                    if(roleops)
                    {
                       this.contenttype.groups = this.contenttype.groups.slice();
                       this.contenttype.groups.unshift(roleops);
                    }
                this.fieldData = results.data.data.data
                if(Array.isArray(results.data.data.data) && !results.data.data.data.length)
                    this.fieldData = {}
                this.contexts = results.data.data.context
                this.options = results.data.data.options
                this.ct = results.data.ctt
            });
            }
            dataService.axiosFetch('imagestyles').then(results => {
                const entries = Object.entries(results)
                this.styles.push({'value': '','title':  'Original'});
                for (const [r, key] of entries) {
                    this.styles.push({'value': r,'title':  r + ' - ' + key[0]+ ' x '+ key[1]+'px'});
                }
            });
              dataService.axiosFetch('gethotellist').then(results => {
 results.forEach(obj => {
                    this.hotels.push({'value': obj.id, 'label': obj.title});
                });

            });
            dataService.axiosFetch('languages').then(results => {
                this.languages = results;
                results.forEach(obj => {
                    this.translations[obj.short] = {};
                });

            });

        }
    };
</script>
<style scoped>

    .input-group .input-group-prepend .input-group-text {
        border: 0;
    }

    .dynamic-delete-button {
        cursor: pointer;
        position: relative;
        top: 4px;
        font-size: 24px;
        color: #999;
        transition: all 0.3s;
    }

    .add-button i
    {
        position: relative;
        top: -3px;}
    .dynamic-delete-button:hover {
        color: #777;
    }

    .dynamic-delete-button[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }
    .page-title
    {min-height:30px}
    .ant-select-selection--single {
        width: 100%;
        height: calc(1.5em + 1.25rem + 2px);
        padding: 0.625rem 0.75rem;
    }
    .date-delete
    {float:left;margin-right:15px;margin-top:-3px;}
    .date-row{
        display: inline-block;
        width: 100%;
        border-bottom: 1px solid #e9ecfa;
        margin: 0px;
        padding: 8px;
        margin-bottom:10px;
        margin-top:-10px;
    }


    .gallery-image-holder
    {float:left;margin:1%;width:23%; height:200px;outline:1px solid #e4e4e4;border:10px solid white;overflow:hidden;position:relative;box-shadow: 8px 8px 10px -12px black;}
    .gallery-image-holder img.big
    {height:100%;width:auto;}

     .demo-image
    {top:0;
         position:absolute;
    width:80px;height:80px;
         border:2px solid #1d2226;
         background-color:#1d2226;
        padding:3px;
         right:-90px;
        display:none;
    }
    .ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field
    {
    height: 40px;
    padding-left: 7px;
    }
    .ant-select-selection--multiple > ul > li, .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    height: 42px;
    margin-top: 0px;
    line-height: 45px;
}
    .sub-translations
    {margin-top:-20px;}
    #app .sub-translations >>> .ant-input, #app .sub-translations >>> .ant-select-selection--single, #app .sub-translations >>> input.form-control, #app .sub-translations >>> .ant-input-number
     {border-color: #dcedff;}
    .sub-translations i
    {color: #aecbdf
    }
    .demo-image img
    {max-width:100%;max-height:100%;}
    .ql-editor >>> strong, .ql-editor >>> b
    {font-weight:bold}
.gallery-images
{width:100%;display:inline-block;}
    .gallery-image-holder .title
    {position:absolute;width:100%;text-align:left;font-size:12px;color:#fff;font-weight:bold;
        max-width:200px;overflow:hidden;bottom:15px;left:5px;}
    .gallery-image-holder .alttag
    {position:absolute;width:100%;text-align:left;font-size:12px;color:#fff;
        max-width:200px;overflow:hidden;bottom:3px;left:5px;}
    .image-setup
    {
        display:block;
        width:100%;
        bottom:-2px;
        right:-2px;
        position:absolute;
        z-index:9;
        text-align:right;
    }
    .list-group
    {display:block;}
    .image-setup i:hover
    {background-color:#d31e40;
    cursor:pointer;}
    .setup-panel
    {display:inline-block;
    width:100%;position:relative;}
    .show-example
    {position:relative;}
    .show-example img
    {margin:0 auto;}
    .show-example:hover .demo-image
    {display:block;}
    .btn-inner--icon i.fad
    {top:0}
    .action-button-gpx, .action-button-gpx >>> .ant-upload.ant-upload-select
    {
     float:right;
     margin-left:3px;
    }

    .action-button-gpx span
    { padding: 10px 0;
    }


    .image-setup i
    {
padding:10px;
        color:#fff;
        font-weight:bold;
        background-color: #000000;
        margin:2px;
    }

    #app .top-bar >>> input.ant-input
    {height:40px;}
    .form-item-holder
    {position:relative;}
   .form-control-label,.ant-select
    {width:100%;
    display:inline-block;}
    .auto-url:hover
    {cursor:pointer;
        color: #0b80e4;
        text-shadow: 0px 0px 1px #0089ff;}
    .auto-url
    {position:absolute;
        top:47px;
    right:18px;}
    .ck.ck-balloon-panel
    {z-index:999999!important;}
    .del-but{position:absolute;right:20px;top:-16px;}
     .edit-card >>> .ant-card-body
     {padding:0;}
   p
{font-weight:initial!important;}

    .ant-tabs >>> .ant-tabs-left-bar .ant-tabs-nav .ant-tabs-tab-active
    {font-weight:initial!important}

</style>
