<template>
<div :class="parseInt(i) === 0 ? 'col-' + cls : 'col-' + cls + ' mt-2'">
  <template v-if="input.type === '1'">
    <label class="form-control-label">{{input.title}}</label>
     <a-input alternative=""

                input-classes="form-control-alternative"
                v-model="fieldData"
    />
    </template>
    <ckeditor  v-if="input.type === '4'" :editor="editor" v-model="fieldData"   @input="(e) => handleChange(e)" :config="editorConfig"></ckeditor>
    <a-input alternative="" v-if="input.type === '6'"
                :label="input.title"
                input-classes="form-control-alternative"
                type="number"
                v-model="fieldData"
                @change="(e) => handleChange(e.target.value)"
    />
       <a-checkbox class="" v-model="fieldData"  v-if="input.type === '7'">{{input.title}}</a-checkbox>

    <a-input alternative="" v-if="input.type === '5'"
                :label="input.title"
                input-classes="form-control-alternative"
                type="number"
                v-model="fieldData"
                @change="(e) => handleChange(e.target.value)"
    />
       <div class="" v-if="input.type === '12'">
                    <label class="form-control-label">{{input.title}}</label>
                <a-select  style="width: 100%"  v-model="fieldData" @change="(e) => handleChange(e)">
                    <a-select-option :value="0">Bez priradenia</a-select-option>
                    <a-select-option v-for="op in options" :key="parseInt(op.value)">{{op.label}}</a-select-option>
                </a-select>
       </div>

             <div class="" v-if="input.type === '11'">


                                                <label class="form-control-label">{{input.title}}</label>

                                                <a-upload-dragger
                                                        name="upload"
                                                        :multiple="true"
                                                        :customRequest="(e) => testo(e)"
                                                        :headers="head"
                                                        :showUploadList="false"
                                                        style="margin-bottom:20px;display:inline-block;width:100%;"
                                                >
                                                    <p class="ant-upload-drag-icon" style="padding-top:20px">
                                                        <a-icon type="file-image" />
                                                    </p>
                                                    <p class="ant-upload-text">
                                                     Klikni, alebo premiestni súbory do tohoto poľa
                                                    </p>

                                                </a-upload-dragger>

<div class="setup-panel" v-if="fieldData">

                                                <div class=" text-right" >
                                                    <label class="form-control-label show-example text-right w-50">globálny štýl galérie
                                                        <a-icon type="picture" :style="{ fontSize: '25px', marginLeft: '15px', color: '#08c' }" ></a-icon>
                                                        <div class="demo-image" v-if="fieldData[0].image.relative">
                                                            <label class="form-control-label"></label>
                                                            <img :src="IMG_URL + (typeof fieldData[0].image.style != 'undefined' ? fieldData[0].image.style : 'contentGrid') + fieldData[0].image.relative" class="small-image">
                                                        </div>
                                                    </label>
                                                    <div class="w-25"  v-if="fieldData">

                                                </div>
                                                    <a-select class="w-50 float-right"  placeholder="globálny štýl galérie" @change="(e) => handleGlobalEdit(e,input.slug)" :options="styles"/>
                                                   </div>
                                                   <div class="gallery-images" :key="fieldData" v-if="fieldData">
    <draggable tag="div" :list="fieldData" v-bind="dragOptions" @start="drag=true" @end="drag=false"  class="list-group" handle=".handle">
    <div v-for="(imageItem,index) in fieldData" class="gallery-image-holder" :key="'img'+input.slug+'_'+index">
        <span class="handle"><div class="title" v-if="imageItem.image.title">{{imageItem.image.title}}</div>
<div class="alttag" v-if="imageItem.image.alt">{{imageItem.image.alt}}</div>
<img class="big" :src="IMG_URL + 'contentGrid' + imageItem.image.relative">
<div class="image-setup"><a-icon type="edit" @click="setupImage(imageItem.image,index,input.slug)"/><a-icon type="delete" @click="deleteThis(index,input.slug)"/></div></span>
    </div></draggable>
</div>

</div>
                                            </div>
    <div class="" v-if="input.type === '19'">
        <label class="form-control-label">{{input.title}}</label>
        <upload :data="fieldData" :dat="fieldData"  @changed="(e) => handleChange(e)"></upload>

    </div>
</div>
</template>

<script>
    import {getURL} from "../api";
    import {authHeader} from "../_helpers";
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import '@ckeditor/ckeditor5-build-classic/build/translations/sk';
    import draggable from "vuedraggable";
    import {IMG_URL} from "../api";

    // eslint-disable-next-line no-unused-vars
    import UploadAdapter from './UploadAdapter';
    import Upload from "./content/Upload";

    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type: mime});
    }
    export default {
        name: "Field",
        components: {Upload,
            draggable,  },
        props:["input","value","cl","i","options","styles"],
        data() {
            return {
                fieldData: this.value,
                url: getURL(),
                head: authHeader(),
                IMG_URL,
                revisionsShow:false,
                imageToSet:{},
                saving:false,
                showoptions:[],
                contexts: {},
                ImagesetupVisible:false,
                temp:{},
                componentKey:0,
                reloadimages:{},
                datatosave: [],
                  editor: ClassicEditor,
                 editorConfig: {
                    heading: {
                        options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                            { model: 'heading3', view: 'h3', title: 'Písané', class: 'ck-heading_heading3' },
                        ]
                    },
                    toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'insertTable', '|', 'imageUpload', 'mediaEmbed', '|', 'undo', 'redo', '|', 'HtmlEmbed', ],
                    table: {
                        toolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
                    },
                    extraPlugins: [this.uploader],
                    language: 'sk',
                },
                cls: this.cl < 5 ? Math.ceil(12/parseInt(this.cl)) : 12
            }},
            watch: {
fieldData() {
    if(Array.isArray(this.fieldData))
     this.$emit("input",JSON.stringify(this.fieldData));
     else
     this.$emit("input",this.fieldData);
}
            },
            mounted() {
 if(this.IsJsonString(this.fieldData))
 this.fieldData = JSON.parse(this.fieldData)
            },
        computed: {
            dragOptions() {
                return {
                    animation: 0,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                };
            }
        },
        methods: {
               sendImage(formData)
            {

                const pa = this;
                this.axios.post(this.url.API_URL + '/upload/content',
                    formData,
                    {
                        headers: authHeader(true)
                    }
                ).then(function (data) {
                    pa.datatosave = data.data[0];

                    let items = [];
// eslint-disable-next-line no-unused-vars
let maxvalue  = 0;

                    var u = {}; u.image = data.data[0];
                    if( pa.fieldData.length > 0)
                    {
                        items = pa.fieldData;
                         items.push(u);

                    }
                    else
                    {
                        items.push(u);
                    }
                    pa.fieldData = items;
//pa.reloadimages[slug] = countitem + 1;

                 //   pa.$emit('changed',  pa.datatosave);

                });
            },
            IsJsonString(str) {
                try {
                    JSON.parse(str);
                } catch (e) {
                    return false;
                }
                return true;
            },
            hasAlpha (context, canvas) {
                var data = context.getImageData(0, 0, canvas.width, canvas.height).data,
                    hasAlphaPixels = false;
                for (var i = 3, n = data.length; i < n; i+=4) {
                    if (data[i] < 255) {
                        hasAlphaPixels = true;
                        break;
                    }
                }
                return hasAlphaPixels;
            },
               testo(e)
            {

                var img = new Image;
                let formData = new FormData();
                const pa = this;
                img.onload = function() {

                        formData.append('upload', e.file);

                    pa.sendImage(formData);

                }

                img.src = URL.createObjectURL(e.file);

                return false
            },
             normFile(e) {

                if (Array.isArray(e)) {
                    return e;
                }
                return e && e.fileList;


            },
            handleEdit() {
                this.$emit('changed', this.datatosave);
            },
              uploader(editor)
            {
                editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
                    return new UploadAdapter( loader );
                };
            },
            handleChange(e) {
                   this.$emit("input",e);

            },
        }
    }
</script>

<style scoped>

    .input-group .input-group-prepend .input-group-text {
        border: 0;
    }

    .dynamic-delete-button {
        cursor: pointer;
        position: relative;
        top: 4px;
        font-size: 24px;
        color: #999;
        transition: all 0.3s;
    }

    .add-button i
    {
        position: relative;
        top: -3px;}
    .dynamic-delete-button:hover {
        color: #777;
    }

    .dynamic-delete-button[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }
    .page-title
    {min-height:30px}
    .ant-select-selection--single {
        width: 100%;
        height: calc(1.5em + 1.25rem + 2px);
        padding: 0.625rem 0.75rem;
    }
    .date-delete
    {float:left;margin-right:15px;margin-top:-3px;}
    .date-row{
        display: inline-block;
        width: 100%;
        border-bottom: 1px solid #e9ecfa;
        margin: 0px;
        padding: 8px;
        margin-bottom:10px;
        margin-top:-10px;
    }


    .gallery-image-holder
    {float:left;margin:1%;width:23%; height:200px;outline:1px solid #e4e4e4;border:10px solid white;overflow:hidden;position:relative;box-shadow: 8px 8px 10px -12px black;}
    .gallery-image-holder img.big
    {height:100%;width:auto;}

     .demo-image
    {top:0;
         position:absolute;
    width:80px;height:80px;
         border:2px solid #1d2226;
         background-color:#1d2226;
        padding:3px;
         right:-90px;
        display:none;
    }
    .ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field
    {
    height: 40px;
    padding-left: 7px;
    }
    .ant-select-selection--multiple > ul > li, .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    height: 42px;
    margin-top: 0px;
    line-height: 45px;
}
    .sub-translations
    {margin-top:-20px;}
    #app .sub-translations >>> .ant-input, #app .sub-translations >>> .ant-select-selection--single, #app .sub-translations >>> input.form-control, #app .sub-translations >>> .ant-input-number
     {border-color: #dcedff;}
    .sub-translations i
    {color: #aecbdf
    }
    .demo-image img
    {max-width:100%;max-height:100%;}
    .ql-editor >>> strong, .ql-editor >>> b
    {font-weight:bold}
.gallery-images
{width:100%;display:inline-block;}
    .gallery-image-holder .title
    {position:absolute;width:100%;text-align:left;font-size:12px;color:#fff;font-weight:bold;
        max-width:200px;overflow:hidden;bottom:15px;left:5px;}
    .gallery-image-holder .alttag
    {position:absolute;width:100%;text-align:left;font-size:12px;color:#fff;
        max-width:200px;overflow:hidden;bottom:3px;left:5px;}
    .image-setup
    {
        display:block;
        width:100%;
        bottom:-2px;
        right:-2px;
        position:absolute;
        z-index:9;
        text-align:right;
    }
    .list-group
    {display:block;}
    .image-setup i:hover
    {background-color:#d31e40;
    cursor:pointer;}
    .setup-panel
    {display:inline-block;
    width:100%;position:relative;}
    .show-example
    {position:relative;}
    .show-example img
    {margin:0 auto;}
    .show-example:hover .demo-image
    {display:block;}
    .btn-inner--icon i.fad
    {top:0}
    .action-button-gpx, .action-button-gpx >>> .ant-upload.ant-upload-select
    {
     float:right;
     margin-left:3px;
    }

    .action-button-gpx span
    { padding: 10px 0;
    }


    .image-setup i
    {
padding:10px;
        color:#fff;
        font-weight:bold;
        background-color: #000000;
        margin:2px;
    }

    #app .top-bar >>> input.ant-input
    {height:40px;}
    .form-item-holder
    {position:relative;}
   .form-control-label,.ant-select
    {width:100%;
    display:inline-block;}
    .auto-url:hover
    {cursor:pointer;
        color: #0b80e4;
        text-shadow: 0px 0px 1px #0089ff;}
    .auto-url
    {position:absolute;
        top:47px;
    right:18px;}
    .ck.ck-balloon-panel
    {z-index:999999!important;}
    .del-but{position:absolute;right:20px;top:8px;}
     .edit-card >>> .ant-card-body
     {padding:0;}
   p
{font-weight:initial!important;}

    .ant-tabs >>> .ant-tabs-left-bar .ant-tabs-nav .ant-tabs-tab-active
    {font-weight:initial!important}

</style>
