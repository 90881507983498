<template>
  <div style="height: 540px" class="leaflet-map">
    <LMap :zoom="zoom" ref="map" :options="{touchZoom: false, doubleClickZoom: false, closePopupOnClick: false,   zoomSnap: false,   zoomDelta: false,   touchZoom: false  }"  @dblclick="onMapClick" :center="[ position.lat || userLocation.lat || defaultLocation.lat, position.lng || userLocation.lng || defaultLocation.lng]">
   <l-tile-layer
      :url="tileProvider.url"
      :attribution="tileProvider.attribution"
    />
       <l-marker
      v-if="position.lat && position.lng"
      visible
      draggable
      :icon="icon"
      :lat-lng="position"
      @dragstart="dragging = true"
      @dragend="dragging = false"
    >
    </l-marker>
    <l-geo-json v-if="gpxVisible" :geojson="geoJson"></l-geo-json>
      
    </LMap>
     <button class="button" @click="onClickButton">Hide/Show Track</button>
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import { LMap, LTileLayer, LMarker, LGeoJson} from "vue2-leaflet"; 
import { icon  } from "leaflet";  
    import {dataService} from "../../_services";
    import { DOMParser } from 'xmldom'
  import { gpx } from "@tmcw/togeojson";   
   // eslint-disable-next-line no-undef
   delete L.Icon.Default.prototype._getIconUrl;
 // eslint-disable-next-line no-undef
 L.Icon.Default.mergeOptions({  
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),  
  iconUrl: require('leaflet/dist/images/marker-icon.png'),  
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')  
})
export default {
  name: "MapLeaflet",
  components: {
    LMap,
    LTileLayer, 
    LMarker,
    LGeoJson
  },
  props: {
     value: {
      type: [Object, Boolean],    
      default: () => (false)
    }, 
    gpxContent: {
      type: String,
      default:  () => (false)
    },
    defaultLocation: {
      type: Object,
      default: () => ({
          lat: 48.69458640884518,
        lng: 19.615691902944906
      })
    }
  },
  computed: {
    tooltipContent() {
      if (this.dragging) return "...";
      if (this.loading) return "Loading...";
      let add = this.address.split(",");
      return `<strong>${add[0]}<br/>${add[1]}</strong>`;
    }
  },
  data() {
    return {
      url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
      zoom: 12, 
      bounds: null, 
      gpxVisible:false,  
       loading:false,
       geoJson: [],
      userLocation: {}, 
      centerPoint: {ltg:0,lng:0},
      icon: icon({ 
        iconUrl: require('leaflet/dist/images/marker-icon.png'),  
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
        iconSize: [24, 40],
        iconAnchor: [12, 40]
      }),
      position: {},
      address:"",
      tileProvider: {
        attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> | <a target="_blank" href="https://www.mojcykloregion.sk">mojcykloregion.sk</a>',
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      }, 
      dragging:false
    };
  },
    watch: {
    position: {
      deep: true,
      async handler(value) {
        this.$emit("changed", { position: value, json: this.geoJson, centerpoint: this.centerpoint });
      },
    }
  },
  mounted() {
      if(this.value)
     this.position = this.value;
if(this.value)
{
     dataService.axiosPost("getgpx", {'file': this.gpxContent}).then(results => { 
      
     if(results.data.data[0])
     { 
      const kml = new DOMParser().parseFromString(results.data.data[0]);
      let gg =  gpx(kml);  
if(gg.features[0].geometry.type === "Feature")
{
  this.position = {lat: gg.features[0].geometry.coordinates[1], lng: gg.features[0].geometry.coordinates[0]}
}
if(gg.features[0].geometry.type === "LineString")
 {
   this.position = {lat: gg.features[0].geometry.coordinates[0][1], lng: gg.features[0].geometry.coordinates[0][0]}
 }

this.geoJson = gg; 
this.gpxVisible = true;
     }
        });   
        }
  },
    methods: {
        onGpxLoaded() {
           
  }, 
     onMapClick(value) {
      // place the marker on the clicked spot
      this.position = value.latlng;
    }, 
     getUserPosition() {
      if (navigator.geolocation) {
        // get GPS position
        navigator.geolocation.getCurrentPosition(pos => {
          // set the user location
          this.userLocation = {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude
          };
        });
      }
    },
    onClickButton() {
    this.gpxVisible = !this.gpxVisible;
  }
  }
};
</script>
<style>
.leaflet-map
{
    position:relative;
    width:100%;
    overflow:hidden;
    max-height:540px;
    display:inline-block;
    box-shadow:10px 8px 25px -20px
}
</style>